<template>

<section id="form-encuesta-escalalineal">
  <div class="row">
      <div class="col-12">
        <label class="form-label text-secondary">Ingresa la pregunta</label>
          <input 
            type="text" 
            class="form-control input-custom"
            maxlength="170"
            v-model="texto_pregunta"
          >
      </div>
    </div>

    <div class="row">
      <div class="col-12">
        <div class="border-bottom mb-3 pb-3"></div>
      </div>
    </div>

    <div class="row">
      <div class="col-12">
        <label class="form-label text-secondary">Respuesta</label>
        <div class="row">
          <div class="col-12 col-lg-6">
            <label class="form-label text-secondary">Desde</label>
            <v-select
              class="selvue-custom"
              :options="optionsFrom"
              v-model="selectedFrom"
              @input="setTipoSeleccionFrom(selectedFrom)"
              :clearable="false"
            ></v-select>
          </div>
          <div class="col-12 col-lg-6">
            <label class="form-label text-secondary">Hasta</label>
            <v-select
              class="selvue-custom"
              :options="optionsTo"
              label="opcion"
              v-model="selectedTo"
              @input="setTipoSeleccionTo(selectedTo)"
              :clearable="false"
            ></v-select>
          </div>
        </div>
      </div>
    </div>

    <div class="row flex-grow-1">
      <div class="col-12 d-flex align-items-end justify-content-end">
        <button 
          v-if="boton_enviar == 'Guardar'"
          class="btn btn-custom-color-white border border-round-50 mw-100 me-2" 
          @click="resetForm()"
          >
          Cancelar
        </button>
        <button
          class="btn btn-custom-color-blue border-round-50 mw-100"
          @click="addOrEdit()"
          :disabled="!isValidQuestion"
        >
        {{ boton_enviar }}
        <i class="fa-solid fa-arrow-right ps-2"></i>
        </button>
      </div>
    </div>
</section>
</template>

<script>
import { mapActions, mapState } from "vuex";

export default {
  props: {
    index: { type: Number, },
    editingPregunta: { type: Object, },
  },
  data() {
    return {
      texto_pregunta: "",
      selectedFrom: 0,
      selectedTo: 2,
      optionsFrom: [0, 1],
      optionsTo: this.generateOptions(1, 10),
      boton_enviar: "Añadir",
      id_pregunta: "0",
      opciones_edit: []
    };
  },

  computed: {
    ...mapState("encuestasModule", ["encuesta"]),

    isValidQuestion() {
      /* TODO: añadir validación -> alternativas pobladas */
      return this.texto_pregunta.trim() !== "";
    },
  },

  methods: {
    ...mapActions("encuestasModule", ["addPreguntaToEncuesta", "updateQuestionInEncuesta"]),

    setEditingQuestion() {
      this.id_pregunta = this.editingPregunta.id_pregunta;
      this.texto_pregunta = this.editingPregunta.pregunta;
      this.selectedFrom = +this.editingPregunta.opciones[0].opcion;
      this.selectedTo = +this.editingPregunta.opciones[this.editingPregunta.opciones.length - 1].opcion;
      this.opciones_edit = this.editingPregunta.opciones;
      this.boton_enviar = "Guardar";
    },

    addOrEdit() {
      const action = this.boton_enviar === "Añadir" ? this.addQuestion : this.editQuestion;
      action();
    },

    addQuestion() {
      this.addPreguntaToEncuesta(this.createQuestionObject(this.generateOptions));
      this.resetForm();
    },
    editQuestion() {
      this.updateQuestionInEncuesta({ index: this.index, pregunta: this.createQuestionObject(this.generateOptionsForEdit) });
      this.resetForm();
    },
    resetForm() {
      this.texto_pregunta = "";
      this.id_pregunta = "0";
      this.selectedFrom = 0;
      this.selectedTo = 2;
      this.optionsFrom = [0, 1];
      this.optionsTo = this.generateOptions(1, 10);
      this.opciones_edit = [];
      this.boton_enviar = "Añadir";
      this.$emit("add-or-edit-pregunta");
      this.$Progress.finish();
    },
    
    createQuestionObject(optionsGeneratorFunction) {
      return {
        id_pregunta: this.id_pregunta,
        tipo_pregunta: "4",
        pregunta: this.texto_pregunta,
        editable: "1",
        calificacion_puntos: "1",
        calificacion_estrellas: "0",
        opciones: optionsGeneratorFunction(this.selectedFrom, this.selectedTo),
      };
    },

    generateOptions(from, to) {
      return Array.from({ length: to - from + 1 }, (_, i) => ({
        id_alternativa: "0",
        genera_qr: "0",
        correcta_alternativa: "1",
        opcion: `${from + i}`,
      }));
    },

    generateOptionsForEdit(from, to) {
      let antiguo = this.opciones_edit;
      let nuevo = this.generateOptions(from, to);
      // cambiando solo ids alternativas antiguas
      let optionEdit = [];
      // son iguales
      if (antiguo.length == nuevo.length) {
        for (let i = 0; i < antiguo.length; i++) {
          optionEdit.push({
            id_alternativa: antiguo[i].id_alternativa,
            genera_qr: "0",
            correcta_alternativa: nuevo[i].correcta_alternativa,
            opcion: nuevo[i].opcion,
          });
        }
      }
      // antiguo < nuevo
      if (antiguo.length < nuevo.length) {
        for (let i = 0; i < antiguo.length; i++) {
          nuevo[i].id_alternativa = antiguo[i].id_alternativa;
        }
        optionEdit = nuevo;
      }
      // antiguo > nuevo
      if (antiguo.length > nuevo.length) {
        for (let i = 0; i < nuevo.length; i++) {
          nuevo[i].id_alternativa = antiguo[i].id_alternativa;
        }
        optionEdit = nuevo;
        for (let i = nuevo.length; i < antiguo.length; i++) {
          optionEdit.push({
            id_alternativa: antiguo[i].id_alternativa,
            genera_qr: "0",
            correcta_alternativa: "",
            opcion: "delete",
          });
        }
      }
      return optionEdit;
    },



    setTipoSeleccionFrom(newValue) {
      const newToOptionsStart = newValue + 1;
      this.selectedFrom = newValue;
      this.optionsTo = this.generateOptions(newToOptionsStart, 10);
    },

    setTipoSeleccionTo(newValue) {
      this.selectedTo = +newValue.opcion;
    },
  },
};
</script>
