<template>
<div >
  <div class="input-group">
    <div 
      v-if="showCorrectOption" 
      class="input-group-text" 
      :class="{ 'rounded-end': mediaType === 'Imagen' || mediaType === 'Video' }"
    >
      <input 
        class="form-check-input mt-0" 
        type="radio" 
        :name="`correctOption-${alternativeIndex}`"
        :checked="alternative.correcta_alternativa === '1'"
        @change="$emit('update-correct', alternativeIndex)"
      >
    </div>
    <input v-if="mediaType === 'Texto'" 
      type="text" 
      class="form-control input-custom"
      :placeholder="'Alternativa ' + (alternativeIndex + 1)"
      v-model="alternative.data"
    >

    <div v-if="(mediaType === 'Imagen' || mediaType === 'Video') && !alternative.data" style="margin:auto;">
      <label class="btn btn-primary">
        Seleccionar archivo
        <input 
          type="file"
          class="form-control input-custom"
          @change="handleFileChange"
          :accept="mediaType === 'Video' ? 'video/*' : 'image/*'"
          style="display: none;"
        >
      </label>
    </div>
    
    <MediaCustomCard
      v-if="(mediaType === 'Imagen' || mediaType === 'Video') && alternative.data"
      :dataURL="alternative.data"
      :item="alternative.metadata"
      @deleteItem="resetAlternative"
      style="margin:auto;"
    />

  </div>
  <a 
    href="javascript:void(0)"
    v-show="showDelete" 
    @click="$emit('delete', alternativeIndex)"
    class="input-custom-icon-right"
  >
    <i class="fa-solid fa-trash-can"></i>
  </a>
  <div v-if="mediaType === 'Texto'" class="form-text text-end charcount">
    {{ alternative.data.length }}/{{ maxChars }}
  </div>



</div>
</template>

<script>
import moment from "moment-timezone";
import MediaCustomCard from '../tv/MediaCustomCard.vue';

export default {
  components: {
    MediaCustomCard
  },

  props: {
    mediaType: { type: String, required: true },
    alternative: { type: Object, required: true },
    showCorrectOption: { type: Boolean, default: false },
    showDelete: { type: Boolean, default: false },
    alternativeIndex: { type: Number, required: true },
    maxChars: { type: Number, default: 150 }
  },
  methods: {
    handleFileChange(event) {
      const file = event.target.files[0];
      if (file) {
        const reader = new FileReader();
        reader.onload = (e) => {
          const dataURL = e.target.result;
          if (file.type.startsWith('video')) {
            const video = document.createElement('video');
            video.src = dataURL;
            video.addEventListener('loadedmetadata', () => {
              const durationFormatted = moment.utc(video.duration * 1000).format('HH:mm:ss');
              this.alternative.data = dataURL;
              this.alternative.metadata.tiempo_video = durationFormatted;
              this.alternative.metadata.titulo_video = file.name;
            });
          } else if (file.type.startsWith('image')) {
            this.alternative.data = dataURL;
          }
        };
        reader.readAsDataURL(file);
      }
    },

    resetAlternative() {
      this.alternative.data = "";
      this.alternative.metadata = {};
    }

  }
};
</script>