<template>

<section id="form-encuesta-step-2" class="d-flex flex-column flex-grow-1">
  <div class="row flex-grow-1 mt-3">
    <div class="col-12 col-lg-3 d-flex flex-column mt-3 mt-lg-0">
      <h5 class="font-main text-secondary">Selecciona elemento</h5>
      <QuestionPickerPanel
        :preguntas="preguntas"
        :currentComponent="show_component"
        @changeComponent="handleComponentChange"
      />
    </div>

    <div class="col-12 col-lg-6 d-flex flex-column mt-3 mt-lg-0">
      <h5 class="font-main text-secondary">Añade contenido</h5>
      <div class="card flex-grow-1">
        <div class="card-body d-flex flex-column">
          <h6 class="font-main-bold color-main text-center">Contenido interior pregunta</h6>
            <component
              ref="addQuestion"
              :is="show_component"
              :index="index_pregunta"
              :editingPregunta="activePregunta"
              @add-or-edit-pregunta="cleanPregunta"
              @cancel="cancelQuestion"
              class='d-flex flex-column flex-grow-1'
            />
        </div>
      </div>
    </div>

    <div class="col-12 col-lg-3 d-flex flex-column mt-3 mt-lg-0">
      <h5 class="font-main text-secondary">Edita y reorganiza</h5>
      <QuestionListPanel
        :preguntas="encuesta.preguntas"
        @edit-question="enableQuestionEditing"
        @delete-question="deletePreguntaByIndex"
      />
    </div>
  </div>

  <div class="row">
    <div class="col-12">
      <div class="d-flex align-items-center justify-content-end pt-2 mt-4 border-top border-2">
        <button 
          class="btn btn-custom-color-white border border-round-50 mw-100 me-2" 
          @click="backStep()">
          Volver
        </button>
        <button
          class="btn btn-custom-color-blue border-round-50 mw-100"
          @click="nextStep()"
          :disabled="!comprobar()"
        >
          Siguiente
        </button>
      </div>
    </div>
  </div>

  <Status
    v-if="open_comprobar_status"
    :msg="modal_status_msg"
    :status="modal_status"
    @close="statusComprobar"
  />
</section>

</template>

<script>
import QuestionPickerPanel from './QuestionPickerPanel.vue';
import QuestionListPanel from './QuestionListPanel.vue';
import AddAlternativas from "./AddAlternativas.vue";
import AddCalificacion from "./AddCalificacion.vue";
import AddEscalaLineal from "./AddEscalaLineal.vue";
import AddDesarrollo from "./AddDesarrollo.vue";
import AddSatisfaccion from "./AddSatisfaccion.vue";
import { mapState, mapActions } from "vuex";
import Status from "../Modales/Status.vue";
export default {
  components: {
    QuestionPickerPanel,
    QuestionListPanel,
    AddAlternativas,
    AddCalificacion,
    AddEscalaLineal,
    AddDesarrollo,
    AddSatisfaccion,
    Status,
  },
  data() {
    return {
      show_component: "AddAlternativas",
      index_pregunta: -1,
      activePregunta: {},
      open_comprobar_status: false,
      modal_status_msg: "",
      modal_status: false,

      //FF
      preguntas: [
        { id: 1, label: "Alternativas", component: "AddAlternativas", icon: "fa-solid fa-list" },
        { id: 2, label: "Calificación", component: "AddCalificacion", icon: "fa-regular fa-star" },
        { id: 3, label: "Escala lineal", component: "AddEscalaLineal", icon: "fa-solid fa-ellipsis" },
        { id: 4, label: "Desarrollo", component: "AddDesarrollo", icon: "fa-solid fa-align-left" },
        { id: 5, label: "Satisfacción", component: "AddSatisfaccion", icon: "fa-regular fa-face-smile" },
      ]
    };
  },

  computed: {
    ...mapState("encuestasModule", ["encuesta"]),
  },
  methods: {
    ...mapActions("encuestasModule", ["deletePreguntaByIndex"]),
    
    getComponentName(pregunta) {
      if (pregunta.tipo_pregunta == "1") return 'AddDesarrollo';
      if (["2","3","6","7"].includes(pregunta.tipo_pregunta)) return 'AddAlternativas';
      if (pregunta.tipo_pregunta == "4" && pregunta.calificacion_estrellas == "1") return 'AddCalificacion';
      if (pregunta.tipo_pregunta == "4" && pregunta.calificacion_estrellas == "0") return 'AddEscalaLineal';
      if (pregunta.tipo_pregunta == "5") return 'AddSatisfaccion';
    },

    handleComponentChange(component, { index, pregunta }) {
      this.activePregunta = pregunta || {};
      this.index_pregunta = pregunta ? index : -1;
      this.show_component = component;
    },

    nextStep() {
      if (this.comprobar() == true) {
        this.$emit("add-encuesta");
      }
    },

    backStep() {
      this.$emit("back-encuesta");
    },

    comprobar() {
      return this.encuesta.preguntas.length != 0;
    },

    cleanPregunta() {
      this.index_pregunta = -1;
      this.activePregunta = {};
    },

    cancelQuestion(){
      this.index_pregunta = -1;
      this.activePregunta = {};
      this.show_component = "AddAlternativas";
    },
    
    enableQuestionEditing(index) {
      const pregunta = this.encuesta.preguntas[index];
      // console.log("pregunta: ", JSON.stringify(pregunta, null, 4));
      this.handleComponentChange(this.getComponentName(pregunta), { index, pregunta });
      this.$nextTick(() => {
        if (this.$refs.addQuestion && typeof this.$refs.addQuestion.setEditingQuestion === 'function') {
          this.$refs.addQuestion.setEditingQuestion();
        }
      });
    },

    statusComprobar() {
      this.open_comprobar_status = false;
    },
  },
};
</script>
