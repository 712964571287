<template>
  <section id="add-encuesta">
    <SectionTabs :tabs="tab_component"/>
    <div class="row mt-3">
      <div class="col-12">
        <h3 class="section-head-title ps-0 mb-5">
          Completa los siguientes pasos para subir una noticia.
        </h3>
        <div class="card card-custom border-round-10 card-shadow border-0 mb-3">
          <div class="card-body d-flex flex-column mh-700">
            <div class="form-step">
                <div 
                  @click="step1()" 
                  class="form-step-item" 
                  :class="handleStepsClass(1)">
                  1
                </div>
                <div 
                  @click="step2()" 
                  class="form-step-item" 
                  :class="handleStepsClass(2)">
                  2
                </div>
                <div 
                  @click="step3()" 
                  class="form-step-item" 
                  :class="handleStepsClass(3)">
                  3
                </div>
            </div>
            <AddComponent
              v-if="showComponent === 1"
              @add-encuesta="nextStep"
              @AlertaPushActivo="PUSHALERTA"
            />
            <AddComponent2
              v-if="showComponent === 2"
              @add-encuesta="nextStep"
              @back-encuesta="backStep"
            />
            <AddComponent3
              v-if="showComponent === 3"
              @back-encuesta="backStep"
              :alerta="alertaPorMientras"
            />
          </div>
        </div>
      </div>
    </div>
  </section>

</template>
<script>
import SectionTabs from "../Section/SectionTabs.vue";
import AddComponent from "./AddComponent.vue";
import AddComponent2 from "./AddComponent2.vue";
import AddComponent3 from "./AddComponent3.vue";
//importar mutacion de apollo
export default {
  name: "AddEncuesta",
  components: {
    SectionTabs,
    AddComponent,
    AddComponent2,
    AddComponent3,
  },
  data() {
    return {
      tab_component: [
        { name: "Gestiona", route: "encuestas-list", },
        { name: "Crear", route: "encuestas-add", },
      ],
      showComponent:1,
      component1: true,
      component2: false,
      component3: false,
      //Se debe eliminar este proceso de alerta y generar nuevo proceso entre view y componentes
      alertaPorMientras: false,

      subtitulo: "Crea tu encuesta",
    };
  },
  mounted() {
    if (this.$route.name == "encuestas-edit"){
      this.tab_component[1].name = "Editar";
      this.subtitulo = "Edita tu encuesta"
    }
  },
  computed: {
  },
  methods: {
    // Esta función se debe eliminar, solo se ocupa dado que esta todo mal realizado entre View, componentes, etc
    PUSHALERTA(value){
      this.alertaPorMientras = value;
    },
    // CAMBIAR // 
    step1(){
      this.showComponent = 1;
    },
    step2(){
      if(this.component2 == true){
        this.showComponent = 2;
      }
    },
    step3(){
      if(this.component3 == true){
        this.showComponent = 3;
      }
    },
    nextStep() {
      if (this.showComponent === 1) {
        this.component2 = true;
        this.showComponent = 2;
      } else if (this.showComponent === 2) {
        this.component3 = true;
        this.showComponent = 3;
      }
    },
    backStep(){
      if (this.showComponent === 2) {
        this.showComponent = 1;
      } else if (this.showComponent === 3) {
        this.showComponent = 2;
      }
    },
    handleStepsClass(status){
      if(status == 1){
        if (this.showComponent == 1) return 'active';
        else if (this.component1 == true) return 'ok';
        else return '';
      }
      if(status == 2){
        if (this.showComponent == 2) return 'active';
        else if (this.component2 == true) return 'ok';
        else return '';
      }
      if(status == 3){
        if (this.showComponent == 3) return 'active';
        else if (this.component3 == true) return 'ok';
        else return '';
      }
    },
  },
};
</script>
