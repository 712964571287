<template>
  <section 
    id="form-encuesta-step-1" 
    class="d-flex flex-column flex-grow-1"
  >
    <div class="row flex-grow-1 mt-3">
      <div class="col-12 col-xl-5">
        <div class="mb-3">
          <label class="form-label text-secondary">Título encuesta</label>
          <input 
            type="text" 
            class="form-control input-custom"
            maxlength="100"
            v-model="nombreEncuesta"
          >
          <div class="form-text text-end">{{ nombreEncuesta.length }}/100</div>
        </div>
        <div class="row mb-3">
          <div class="col-12">
            <label class="form-label text-secondary">Seleccionar tipo de encuesta</label>
            <ul class="list-group mb-2">
              <li 
                class="list-group-item"
                :class="encuesta_type === 'nueva' ?'border-primary' : ''"
              >
                <input 
                  class="form-check-input me-2" 
                  type="radio" 
                  value="nueva"
                  name="tipoEncuesta"
                  id="encuestaNueva"
                  checked
                  @change="onChangeType($event)"
                  >
                <label 
                  class="form-check-label stretched-link link-cursor text-secondary"
                  for="encuestaNueva"
                >
                  Crear nueva encuesta
                </label>
              </li>
            </ul>
            <ul class="list-group">
              <li 
                class="list-group-item"
                :class="encuesta_type === 'enlaces' ?'border-primary' : ''"
                >
                <input 
                  class="form-check-input me-2" 
                  type="radio" 
                  value="enlaces"
                  name="tipoEncuesta"
                  id="encuestaExterna"
                  @change="onChangeType($event)"
                >
                <label 
                  class="form-check-label stretched-link link-cursor text-secondary" 
                  for="encuestaExterna"
                >
                  Encuesta enlace externo
                </label>
              </li>
            </ul>
          </div>
        </div>

        <div
          v-if="encuesta_type === 'enlaces'"
          class="mb-3"
        >
          <label class="form-label text-secondary">Ingresa URL</label>
          <input 
            type="text" 
            class="form-control input-custom"
            maxlength="200"
            placeholder="sitioweb.com/encuesta"
            v-model="urlEncuesta"
          >
          <div class="form-text text-end">{{ urlEncuesta.length }}/200</div>
        </div>

        <div 
          v-if="encuesta_type != 'enlaces'"
          class="mb-3 d-flex align-items-center justify-content-between">
          <label 
            for="encuestaAnonima"
            class="form-check-label link-cursor text-secondary"
          >
            ¿Quieres que la encuesta sea anónima?
          </label>
          <div class="form-check form-switch">
            <input 
              id="encuestaAnonima"
              class="form-check-input" 
              type="checkbox" 
              role="switch"
              v-model="anonima"
            >
          </div>
        </div>
        <div class="mb-3 d-flex align-items-center justify-content-between">
          <label 
            for="alertaPush"
            class="form-check-label link-cursor text-secondary"
          >
            Enviar alerta push
          </label>
          <div class="form-check form-switch">
            <input 
              id="alertaPush"
              class="form-check-input" 
              type="checkbox" 
              role="switch"
              v-model="alertaPush"
              @change="variableAlertaPush"
            >
          </div>
        </div>
        <div class="mb-3 d-flex align-items-center justify-content-between">
          <label 
            for="segmentarEncuesta"
            class="form-check-label link-cursor text-secondary"
          >
            ¿Deseas segmentar la encuesta?
          </label>
          <div class="form-check form-switch">
            <input 
              id="segmentarEncuesta"
              class="form-check-input" 
              type="checkbox" 
              role="switch"
              v-model="segmentar"
              @click="switchSegmentar"
            >
          </div>
        </div>
        <div 
          v-show="segmentar === true"
          class="mb-3"
        >
          <v-select
            class="selvue-custom mb-3"
            :options="segmentaciones_list"
            v-model="select_segmentacion"
            label="nombre_segmentacion"
            placeholder="Tipos de segmentación"
            :clearable="false"
            @input="segmentarSelect(select_segmentacion)"
            >
          </v-select>

          <v-select 
            class="selvue-custom"
            v-show="filtro == '1'"
            :options="filtro_segmentacion"
            v-model="select_filtro_segmentacion"
            label="nombre_gerencia"
            placeholder="Selecciona una gerencia"
            :clearable="false"
            @input="filtroSegmeGerencia(select_segmentacion)"
            >
          </v-select>
          <v-select 
            class="selvue-custom"
            v-show="filtro == '2'"
            :options="filtro_segmentacion"
            v-model="select_filtro_segmentacion"
            label="nombre_sucursal"
            placeholder="Selecciona una sucursal"
            :clearable="false"
            @input="filtroSegmeSucursal(select_segmentacion)"
            >
          </v-select>
          <v-select 
            class="selvue-custom"
            v-show="filtro == '3'"
            :options="filtro_segmentacion"
            v-model="select_filtro_segmentacion"
            label="nombre_sindicato"
            placeholder="Selecciona un sindicato"
            :clearable="false"
            @input="filtroSegmeSindicato(select_segmentacion)"
            >
          </v-select>
          <v-select 
            class="selvue-custom"
            v-show="filtro == '4'"
            :options="filtro_segmentacion"
            v-model="select_filtro_segmentacion"
            label="nombre_genero"
            placeholder="Selecciona un género"
            :clearable="false"
            @input="filtroSegmeGenero(select_segmentacion)"
            >
          </v-select>

        </div>
      </div>
      <div class="col-12 col-xl-7">
        <div 
          v-if="encuesta_type == 'nueva'"
          class="mb-3"
        >
          <label class="form-label text-secondary">
            Ingresa una descripción de la encuesta
          </label>
          <textarea 
            class="form-control" 
            rows="6"
            cols="50"
            maxlength="280"
            v-model="descripcion"
            >
          </textarea>
          <div class="form-text text-end">{{ descripcion.length }}/280</div>
        </div>
        <div 
          v-if="encuesta_type == 'enlaces'"
          class="mb-3">
          <div class="row">
            <div class="col-12 col-sm-6 col-xxl-6">
              <label class="form-label text-secondary">
                Selecciona el día de inicio
              </label>
              <v-date-picker
                locale="es"
                style="width: 100%;"
                color="indigo"
                class="date"
                :min-date="min_date"
                :max-date="end_date"
                v-model="init_date"
              />
            </div>
            <div 
              v-show="init_date"
              class="col-12 col-sm-6 col-xxl-6 mt-3 mt-sm-0">
              <label class="form-label text-secondary">
                Selecciona el día de término
              </label>
              <v-date-picker
                locale="es"
                style="width: 100%;"
                color="indigo"
                class="date"
                :min-date="init_date"
                v-model="end_date"
              />
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-12">
        <div class="d-flex align-items-center justify-content-end pt-2 mt-4 border-top border-2">
          <button 
            class="btn btn-custom-color-white border border-round-50 mw-100 me-2" 
            @click="openQuestionBack()">
            Volver
          </button>
          <button
            class="btn btn-custom-color-blue border-round-50 mw-100"
            @click="nextStep()"
            v-if="encuesta_type == 'nueva'"
            :disabled="!comprobar()"
          >
            Siguiente
          </button>
          <button 
            v-else-if="encuesta_type == 'enlaces'" 
            class="btn btn-custom-color-blue border-round-50 mw-100" 
            :disabled="!comprobar()"
            @click="addOrEdit()"
          >
            {{ crear_editar }}
          </button>
          <button 
            class="btn btn-custom-color-blue border-round-50 mw-100" 
            v-else disabled>
            Siguiente
          </button>
        </div>
      </div>
    </div>

    <Question
        v-if="open_question_modal"
        :msg="question_modal_msg"
        :hideCancel="false"
        @cancel="cancelAdd"
        @accept="addPoll"
      />
      <Question
        v-if="open_question_modal_edit"
        :msg="question_modal_msg"
        :hideCancel="false"
        @cancel="cancelEdit"
        @accept="editPoll"
      />
      <Question
        v-if="open_question_modal_back"
        :msg="question_modal_msg"
        :hideCancel="false"
        @cancel="cancelEdit"
        @accept="goBack()"
      />
      <Status
        v-if="open_modal_status"
        :msg="modal_status_msg"
        :status="true"
        @close="acceptStatus"
      />
      <Spinner v-if="show_spinner" />
  </section>

</template>

<script>
import { mapActions, mapState } from "vuex";
import Question from "../Modales/Question.vue";
import Status from "../Modales/Status.vue";
import Spinner from "../Spinner.vue";

export default {
  components: {
    Question,
    Status,
    Spinner,
  },
  data() {
    return {
      nombreEncuesta: "",
      encuesta_type: "nueva",
      urlEncuesta: "",
      min_date: this.getActualDate(),
      init_date: "",
      end_date: "",
      anonima: false,
      segmentar: false,
      segmentaciones: [],
      tipoSegmentacion: "0",
      descripcion: "",
      alertaPush: false,
      crear_editar: "",
      // modales
      open_question_modal: false,
      question_modal_msg: "",
      open_modal_status: false,
      open_question_modal_edit: false,
      open_question_modal_back: false,
      modal_status_msg: "",
      preguntas: [],
      fecha_inicio: "",
      fecha_fin: "",
      url_resp: "",
      tipo_resp: "",
      show_spinner: false,
      // segmentaciones
      select_segmentacion: '',
      select_filtro_segmentacion: '',
      filtro: '',
      filtro_segmentacion: [],
      select_seg: {
        id_filtro: "0",
        id_selector: [],
      },
    };
  },
  created() {
    if (this.encuesta.preguntas.length !== 0) {
      this.nombreEncuesta = this.encuesta.nombreEncuesta;
      this.descripcion = this.encuesta.descripcion;
      this.anonima = this.stringToBool(this.encuesta.anonima);
      this.tipoSegmentacion = this.encuesta.tipoSegmentacion;
      this.segmentaciones = this.encuesta.segmentaciones;
      this.segmentar = this.encuesta.segmentar;
      this.preguntas = this.encuesta.preguntas;
      this.fecha_inicio = this.encuesta.fecha_inicio;
      this.fecha_fin = this.encuesta.fecha_fin;
    }
    if (this.$route.params.id) {
      this.crear_editar = "Editar";
      this.getEncuesta(this.$route.params.id);
    } else {
      this.crear_editar = "Crear";
    }
  },
  async mounted() {
    // Segmentación
    let segmentaciones_query = {
      id_elementos_segmentacion: this.id_elementos_segmentacion,
      nombre_segmentacion: this.nombre_segmentacion,
    };
    let id_elementos_segmentacion = {
      idEmpresa: this.$ls.get("user").id_empresa_fk,
    };
    await this.getSegmentacionesStore(segmentaciones_query);
    await this.getFiltrosSegmentaciones(id_elementos_segmentacion);
    this.getSegmentacionesList();
  },
  computed: {
    ...mapState("encuestasModule", [
      "segmentaciones_list",
      "encuesta",
      "encuestas_list",
      "segmentaciones_filtradas",
    ]),
  },
  methods: {
    ...mapActions("encuestasModule", [
      "getSegmentacionesStore",
      "getFiltrosSegmentaciones",
      "crearEncuesta",
      "editarEncuesta",
      "cleanEncuesta",
      "setEncuesta",
      "getEncuestaById"
    ]),
    variableAlertaPush(){
      this.$emit("AlertaPushActivo", this.alertaPush);
    },
    getSegmentacionesList() {
      this.segmentacion_tipo = this.segmentaciones_list.map(
        (item) => item.nombre_segmentacion,
      );
    },
    getActualDate() {
      return this.nowChileMoment().format('YYYY-MM-DD');
    },
    // Siguiente
    nextStep() {
      if (this.comprobar() == true) {
        const encuesta = {
          nombreEncuesta: this.nombreEncuesta,
          descripcion: this.descripcion,
          anonima: this.boolToString(this.anonima),
          tipoSegmentacion: this.select_seg.id_filtro,
          segmentaciones: this.select_seg.id_selector,
          segmentar: this.segmentar,
          preguntas: this.preguntas,
          fecha_inicio: this.fecha_inicio,
          fecha_fin: this.fecha_fin,
        };
        this.setEncuesta(encuesta);
        this.$emit("add-encuesta");
      }
    },
    // Traer encuesta por ID
    async getEncuesta(id) {
      this.show_spinner = true;
      const payload = {
        idEmpresa: this.$ls.get("user").id_empresa_fk,
        idEncuesta: id,
      };
      const resEncuesta = await this.getEncuestaById(payload);
      this.show_spinner = false;
      if (!resEncuesta) {
        this.toastGenericError();
        return;         
      }

      //Preguntas
      let preguntasLocal = [];
      if (this.encuesta.preguntas.length != 0) {
        for (let i = 0; i < this.encuesta.preguntas.length; i++) {
          preguntasLocal.push({
            id_pregunta: this.encuesta.preguntas[i].id_pregunta,
            tipo_pregunta: this.encuesta.preguntas[i].tipo_pregunta.id_pregunta_tipo,
            pregunta: this.encuesta.preguntas[i].texto_pregunta,
            editable: this.encuesta.preguntas[i].editable,
            calificacion_puntos: this.boolToString( this.encuesta.preguntas[i].is_points ),
            calificacion_estrellas: this.boolToString( this.encuesta.preguntas[i].is_star ),
            opciones: this.getOpcionesPregunta( this.encuesta.preguntas[i].alternativas ),
          });
        }
      }
      this.nombreEncuesta = this.encuesta.nombre_encuesta;
      this.descripcion = this.encuesta.descripcion;
      this.anonima = this.stringToBool( this.boolToString(this.encuesta.is_anonima) );
      this.tipoSegmentacion = "0";
      this.segmentaciones = "";
      this.segmentar = false;
      this.preguntas = preguntasLocal;
      this.fecha_inicio = this.get_date(this.encuesta.fecha_inicio_encuesta);
      this.fecha_fin = this.get_date(this.encuesta.fecha_termino_encuesta);
      this.url_resp = this.encuesta.url_encuesta;
      this.tipo_resp = this.encuesta.tipo_encuesta.id_tipo_encuesta;
      if (this.tipo_resp == "2") {
        this.encuesta_type = "enlaces";
        this.urlEncuesta = this.url_resp;
        this.init_date = this.fecha_inicio;
        this.end_date = this.fecha_fin;
      }
    },
    get_date(day) {
      let dia = day.toString() + "T00:00:00";
      return dia;
    },
    getOpcionesPregunta(alternativas) {
      let opcionesLocal = [];
      if (alternativas.length != 0) {
        for (let i = 0; i < alternativas.length; i++) {
          opcionesLocal.push({
            id_alternativa: alternativas[i].id_alternativa,
            genera_qr: alternativas[i].genera_qr,
            correcta_alternativa: alternativas[i].correcta_alternativa,
            opcion: alternativas[i].texto_alternativa,
          });
        }
      }
      return opcionesLocal;
    },
    // Publicar o editar
    addOrEdit() {
      // Publicar encuesta con enlace
      if (this.comprobar() == true) {
        if (this.$route.params.id) {
          this.question_modal_msg = "¿Está seguro de editar la encuesta?";
          this.open_question_modal_edit = true;
        } else {
          this.open_question_modal = false;
          this.addPoll();
        }
      }
    },
    // Agregar
    async addPoll() {
      this.show_spinner = true;
      const payload = {
        idEmpresa: this.$ls.get("user").id_empresa_fk,
        nombreEncuesta: this.nombreEncuesta,
        descripcion: "",
        fechaInicio: this.init_date,
        fechaFin: this.end_date,
        tipoEncuesta: "2",
        urlEncuesta: this.urlEncuesta,
        idUsuario: this.$ls.get("user").id_usuario,
        anonima: this.boolToString(this.anonima),
        tipoSegmentacion:this.select_seg.id_filtro,
        alertaPush: this.boolToString(this.alertaPush),
        preguntas: [],
        usuarios: [],
        enlaces: [],
        correoContactos: [],
        segmentaciones: this.select_seg.id_selector,
      };

      const resCrear = await this.crearEncuesta(payload);
      this.show_spinner = false;
      this.open_question_modal = false;
      if (!resCrear) {
        this.$toast.open({
          message:'Lo sentimos ocurrió un error al publicar la encuesta, intentalo nuevamente.',
          type:'error',
          position:'top-right',
          duration:5000
        })
        return;
      }
      this.cleanEncuesta();
      this.modal_status_msg = "Encuesta publicada correctamente";
      this.open_modal_status = true;

    },
    cancelAdd() {
      this.open_question_modal = false;
    },
    // Editar
    async editPoll() {
      this.open_question_modal_edit = false;
      this.show_spinner = true;
      const payload = {
        idEncuesta: this.$route.params.id,
        idEmpresa: this.$ls.get("user").id_empresa_fk,
        nombreEncuesta: this.nombreEncuesta,
        descripcion: "",
        fechaInicio: this.init_date,
        fechaFin: this.end_date,
        tipoEncuesta: "2",
        urlEncuesta: this.urlEncuesta,
        idUsuario: this.$ls.get("user").id_usuario,
        anonima: this.boolToString(this.anonima),
        tipoSegmentacion: this.select_seg.id_filtro,
        alertaPush: this.boolToString(this.alertaPush),
        preguntas: [],
        usuarios: [],
        enlaces: [],
        correoContactos: [],
        segmentaciones: this.select_seg.id_selector,
      };
      const resEditar = await this.editarEncuesta(payload);
      this.show_spinner = false;
      if (!resEditar) {
        this.$toast.open({
          message:'Lo sentimos ocurrió un error al editar la encuesta, intentalo nuevamente.',
          type:'error',
          position:'top-right',
          duration:5000
        })
        return;
      }
      this.cleanEncuesta();
      this.modal_status_msg = "Encuesta editada satisfactoriamente";
      this.open_modal_status = true;
    },

    cancelEdit() {
      this.open_question_modal_edit = false;
    },
    // Funciones
    boolToString(valor) {
      if (valor == true) return "1";
      else return "0";
    },
    stringToBool(valor) {
      if (valor == "1") return true;
      else return false;
    },
    comprobar() {
      return (
        this.nombreEncuesta != "" &&
        (this.descripcion != "" ||
          (this.urlEncuesta != "" &&
            this.init_date != "" &&
            this.end_date != ""))
      );
    },
    // Status
    acceptStatus() {
      this.open_modal_status = false;
      this.modal_status_msg = "";
      this.$router.push({
        name: "encuestas-list",
      });
    },

    // Funciones template
    onChangeType(event) {
      let type = event.target.value;
      this.encuesta_type = type;
      this.urlEncuesta = "";
      this.descripcion = "";
      this.init_date = "";
      this.end_date = "";
      this.alertaPush = false;
    },
    switchSegmentar() {
      this.tipoSegmentacion = "0";
      this.segmentaciones = "";
    },
    segmentarSelect(status){
      this.select_filtro_segmentacion = '';
      this.filtro = status.id_elementos_segmentacion;
      if(this.filtro == '1') this.filtro_segmentacion = this.segmentaciones_filtradas.gerencias;
      if(this.filtro == '2') this.filtro_segmentacion = this.segmentaciones_filtradas.sucursales;
      if(this.filtro == '3') this.filtro_segmentacion = this.segmentaciones_filtradas.sindicatos;
      if(this.filtro == '4') this.filtro_segmentacion = this.segmentaciones_filtradas.generos;
      this.tipoSegmentacion = this.filtro;
    },

    filtroSegmeGerencia(status){
      this.select_seg = {
        id_filtro: "1",
        id_selector: [status.id_gerencia],
      };
      this.segmentaciones = [];
      this.segmentaciones[0] = this.select_filtro_segmentacion ;
    },
    filtroSegmeSucursal(status){

      this.select_seg = {
        id_filtro: "2",
        id_selector: [status.id_sucursal],
      };
      this.segmentaciones = [];
      this.segmentaciones[0] = this.select_filtro_segmentacion ;
    },
    filtroSegmeSindicato(status){

      this.select_seg = {
        id_filtro: "3",
        id_selector: [status.id_sindicato],
      };
      this.segmentaciones = [];
      this.segmentaciones[0] = this.select_filtro_segmentacion ;
    },
    filtroSegmeGenero(status){

      this.select_seg = {
        id_filtro: "4",
        id_selector: [status.id_genero],
      };
      this.segmentaciones = [];
      this.segmentaciones[0] = this.select_filtro_segmentacion ;
    },
    openQuestionBack(){
      this.question_modal_msg = "¿Realmente deseas volver? Se perderán los cambios realizados.";
      this.open_question_modal_back = true;
    },
    goBack(){
      if(this.crear_editar == "Editar"){
        this.$router.push({path:'/encuestas/list',replace:true});
      }else{
        this.$router.push('list');
      }
      
    }
  },
};
</script>
