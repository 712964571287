<template>

<section 
    id="form-encuesta-step-2" 
    class="d-flex flex-column flex-grow-1"
  >
  <div class="row flex-grow-1 mt-3">
    <div class="col-12 col-lg-6">
      <label class="form-label text-secondary">
        Selecciona el día de inicio
      </label>
      <v-date-picker 
        locale="es"
        style="width: 100%" 
        color="indigo" 
        class="date" 
        :min-date="getActualDate()" 
        :max-date="fechaFin"
        v-model="fechaInicio"
      />
    </div>
    <div class="col-12 col-lg-6 mt-3 mt-lg-0">
      <label class="form-label text-secondary">
        Selecciona el día de término
      </label>
      <v-date-picker 
        locale="es"
        style="width: 100%" 
        color="indigo" 
        class="date" 
        :min-date="fechaInicio" 
        v-model="fechaFin"
      />
    </div>
  </div>

  <div class="row">
      <div class="col-12">
        <div class="d-flex align-items-center justify-content-end pt-2 mt-4 border-top border-2">
          <button 
            class="btn btn-custom-color-white border border-round-50 mw-100 me-2" 
            @click="backStep()">
            Volver
          </button>
          <button
            class="btn btn-custom-color-blue border-round-50 mw-100"
            @click="addOrEdit()"
            :disabled="!comprobar()"
          >
          {{ crear_editar}}
          </button>
        </div>
      </div>
    </div>

    <Question 
      v-if="open_question_modal" 
      :msg="question_modal_msg" 
      :hideCancel="false" 
      @cancel="cancelAdd"
      @accept="addPoll" 
    />
    <Question
      v-if="open_question_modal_edit" 
      :msg="question_modal_msg" 
      :hideCancel="false" 
      @cancel="cancelEdit"
      @accept="editPoll"
    />

    <Spinner v-if="show_spinner"/>

</section>
</template>

<script>
import { mapActions, mapState } from "vuex";
import Question from "../Modales/Question.vue";
import Spinner from "../Spinner.vue";

export default {
  components: {
    Question,
    Spinner
  },
  props:{
    alerta: Boolean
  }, 
  data() {
    return {
      show_spinner: false,
      fechaInicio: "",
      fechaFin: "",
      alertaPush: this.alerta,
      // Modales
      open_question_modal: false,
      question_modal_msg: "",
      open_question_modal_edit: false,
      crear_editar:"",
      eliminadas: [],
    };
  },

  created() {
    if (this.$route.params.id) {
      this.crear_editar = "Editar";
    } else {
      this.crear_editar = "Crear";
    }
    if (this.encuesta.preguntas.length !== 0) {
      this.min_date = this.encuesta.fecha_inicio
      this.fechaInicio = this.encuesta.fecha_inicio;
      this.fechaFin = this.encuesta.fecha_fin;
    }
    let data2 = JSON.parse(localStorage.getItem("preguntas_deleted"));
    if(data2 != null){
      this.eliminadas = data2;
    }

    // ir a paso 2
    if(this.encuesta.preguntas.length == 0){
      this.backStep()
    }
  },

  computed: {
    ...mapState("encuestasModule", ["encuesta", "preguntas_deleted"]),
  },

  methods: {
    ...mapActions("encuestasModule", ["crearEncuesta", "editarEncuesta", "cleanEncuesta"]),

    // Atras
    getActualDate() {
      let today = new Date();
      return today;
    },
    
    backStep() {
      this.encuesta.fecha_inicio = this.fechaInicio;
      this.encuesta.fecha_fin = this.fechaFin;
      this.$emit("back-encuesta");
    },

    // Publicar o editar
    addOrEdit(){
      if (this.comprobar() == true) {
        if (this.$route.params.id) {
          this.question_modal_msg = "¿Está seguro de editar la encuesta?";
          this.open_question_modal_edit = true;
        } else {
          this.addPoll();
        }
      }
    },

    // Publicar
    async addPoll(){
      this.show_spinner = true;
      let data_ = {
        idEmpresa: this.$ls.get("user").id_empresa_fk,
        nombreEncuesta: this.encuesta.nombreEncuesta,
        descripcion: this.encuesta.descripcion,
        fechaInicio: this.fechaInicio,
        fechaFin: this.fechaFin,
        tipoEncuesta: "1",
        urlEncuesta: "",
        idUsuario: this.$ls.get("user").id_usuario,
        anonima: this.encuesta.anonima,
        tipoSegmentacion: this.encuesta.tipoSegmentacion,
        alertaPush: this.boolToString(this.alertaPush),
        preguntas: this.encuesta.preguntas,
        usuarios: [],
        enlaces: [],
        correoContactos: [],
        segmentaciones: this.encuesta.segmentaciones,
      }
      const resCrear = await this.crearEncuesta(data_);
      this.show_spinner = false;
      if (resCrear) {
        this.$toast.open({
          message: "La encuesta ha sido añadido correctamente.",
          type: "success",
          duration: 6000,
          position: "top-right",
        });
      } else {
        this.$toast.open({
          message: "El proceso de creación de encuesta ha fallado, favor de contactar a soporte@carpetres.cl.",
          type: "error",
          duration: 0,
          position: "top-right",
        });
      }
      this.cleanEncuesta();
      localStorage.removeItem("preguntas_deleted");
      this.$router.push({ name: "encuestas-list" });
    },

    cancelAdd() {
      this.open_question_modal = false;
    },
    // Editar
    async editPoll(){
      this.show_spinner = true;
      this.encuesta.preguntas = this.agregarEliminadas();
      let data_ = {
        idEncuesta: this.$route.params.id,
        idEmpresa:  this.$ls.get("user").id_empresa_fk,
        nombreEncuesta: this.encuesta.nombreEncuesta,
        descripcion: this.encuesta.descripcion,
        fechaInicio:  this.fechaInicio,
        fechaFin: this.fechaFin,
        tipoEncuesta: "1",
        urlEncuesta: "",
        idUsuario: this.$ls.get("user").id_usuario,
        anonima: this.encuesta.anonima,
        tipoSegmentacion: this.encuesta.tipoSegmentacion,
        alertaPush: this.boolToString(this.alertaPush),
        preguntas: this.encuesta.preguntas,
        usuarios: [],
        enlaces: [],
        correoContactos: [],
        segmentaciones: this.encuesta.segmentaciones,
      }

      const resEdit = await this.editarEncuesta(data_);
      this.show_spinner = false;

      if (resEdit) {
        this.$toast.open({
          message: "La encuesta ha sido editada correctamente",
          type: "success",
          duration: 6000,
          position: "top-right",
        });
      } else {
        this.$toast.open({
          message: "El proceso de creación de encuesta ha fallado, favor de contactar a soporte@carpetres.cl.",
          type: "error",
          duration: 0,
          position: "top-right",
        });
      }
      this.cleanEncuesta();
      localStorage.removeItem("preguntas_deleted");
      this.$router.push({ name: "encuestas-list" });
    },

    agregarEliminadas(){
      let preguntas = this.encuesta.preguntas;
      if(this.eliminadas.length != []) {
        this.eliminadas.forEach(a => {
          preguntas.push({
            id_pregunta: a.id_pregunta,
            tipo_pregunta : a.tipo_pregunta,
            pregunta: "delete",
            editable: "1",
            calificacion_puntos: "0",
            calificacion_estrellas: "0",
            opciones: [],
          });
        });
      }
      return preguntas;
    },
    cancelEdit(){
      this.open_question_modal_edit = false;
    },
    // Funciones
    boolToString(valor){
      if(valor == true) return "1";
      else return "0";
    },
    stringToBool(valor){
      if(valor == "1") return true;
      else return false;
    },
    comprobar(){
      return (this.fechaInicio != "" && this.fechaFin != "");
    }
  },
};
</script>
