<template>

  <section id="form-encuesta-satisfaccion">
    <div class="row">
      <div class="col-12">
        <label class="form-label text-secondary">Ingresa la pregunta</label>
          <input 
            type="text" 
            class="form-control input-custom"
            maxlength="170"
            v-model="texto_pregunta"
          >
      </div>
    </div> 

    <div class="row">
      <div class="col-12">
        <div class="border-bottom mb-3 pb-3"></div>
      </div>
    </div>

    <div class="row">
      <div class="col-12">
        <label class="form-label text-secondary">Respuesta</label>
        <div class="row">
          <div class="col-12 col-lg-4">
            <div class="card d-flex align-items-center">
              <img 
                src="@/assets/img/encuestas_icons/emoji-red.svg" 
                width="80"
                class="m-3"
              />

            </div>
          </div>
          <div class="col-12 col-lg-4">
            <div class="card d-flex align-items-center">
              <img 
                src="@/assets/img/encuestas_icons/emoji-grey.png"
                width="80"
                class="m-3"
              />

            </div>
          </div>
          <div class="col-12 col-lg-4">
            <div class="card d-flex align-items-center">
              <img 
                src="@/assets/img/encuestas_icons/emoji-green.png"
                width="80"
                class="m-3"
              />

            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row flex-grow-1">
      <div class="col-12 d-flex align-items-end justify-content-end">
        <button 
          v-if="boton_enviar == 'Guardar'"
          class="btn btn-custom-color-white border border-round-50 mw-100 me-2" 
          @click="resetForm()"
          >
          Cancelar
        </button>
        <button
          class="btn btn-custom-color-blue border-round-50 mw-100"
          @click="addOrEdit()"
          :disabled="!isValidQuestion"
        >
        {{ boton_enviar }}
        <i class="fa-solid fa-arrow-right ps-2"></i>
        </button>
      </div>
    </div>

  </section>

</template>

<script>
import { mapActions, mapState } from "vuex";

export default {
  props: {
    index: { type: Number, },
    editingPregunta: { type: Object, },
  },
  data() {
    return {
      texto_pregunta: "",
      boton_enviar: "Añadir",
      id_pregunta: "0",
      opciones_edit: [],
    };
  },

  computed: {
    ...mapState("encuestasModule", ["encuesta"]),

    isValidQuestion() {
      /* TODO: añadir validación -> alternativas pobladas */
      return this.texto_pregunta.trim() !== "";
    },

  },

  methods: {
    ...mapActions("encuestasModule", ["addPreguntaToEncuesta", "updateQuestionInEncuesta"]),

    setEditingQuestion() {
      this.id_pregunta = this.editingPregunta.id_pregunta;
      this.texto_pregunta = this.editingPregunta.pregunta;
      this.opciones_edit = this.editingPregunta.opciones;//[]
      this.boton_enviar = "Guardar";
    },

    addOrEdit() {
      const action = this.boton_enviar === "Añadir" ? this.addQuestion : this.editQuestion;
      action();
    },

    addQuestion() {
      this.addPreguntaToEncuesta(this.createQuestionObject(this.generateOptions));
      this.resetForm();
    },

    editQuestion() {
      this.updateQuestionInEncuesta({ index: this.index, pregunta: this.createQuestionObject(this.generateOptionsForEdit) });
      this.resetForm();
    },

    resetForm() {
      this.texto_pregunta = "";
      this.id_pregunta = "0";
      this.opciones_edit = [];
      this.boton_enviar = "Añadir";
      this.$emit("add-or-edit-pregunta");
      this.$Progress.finish();
    },

    createQuestionObject(optionsGeneratorFunction) {
      return {
        id_pregunta: this.id_pregunta,
        tipo_pregunta: "5",
        pregunta: this.texto_pregunta,
        editable: "1",
        calificacion_puntos: "0",
        calificacion_estrellas: "0",
        opciones: optionsGeneratorFunction(),
      };
    },

    generateOptions() {
      return [
        {
          id_alternativa: "0",
          genera_qr: "0",
          correcta_alternativa: "1",
          opcion:
            "https://storage.googleapis.com/c3-bucket-01/iconos_encuestas/icono_wrong.png",
        },
        {
          id_alternativa: "0",
          genera_qr: "0",
          correcta_alternativa: "1",
          opcion:
            "https://storage.googleapis.com/c3-bucket-01/iconos_encuestas/icono_doubt.png",
        },
        {
          id_alternativa: "0",
          genera_qr: "0",
          correcta_alternativa: "1",
          opcion:
            "https://storage.googleapis.com/c3-bucket-01/iconos_encuestas/icono_smiley.png",
        },
      ];
    },

    generateOptionsForEdit() {
      const options = this.generateOptions();
      // cambiando solo ids alternativas antiguas
      for (let i = 0; i < 3; i++) {
        options[i].id_alternativa = this.opciones_edit[i].id_alternativa;
      }
      return options;
    },

  },
};
</script>
