<template>

  <section id="form-encuesta-desarrollo">
    <div class="row">
      <div class="col-12">
        <label class="form-label text-secondary">Ingresa la pregunta</label>
          <input 
            type="text" 
            class="form-control input-custom"
            maxlength="170"
            v-model="texto_pregunta"
          >
      </div>
    </div>

    <div class="row flex-grow-1">
      <div class="col-12 d-flex align-items-end justify-content-end">
        <button 
          v-if="boton_enviar == 'Guardar'"
          class="btn btn-custom-color-white border border-round-50 mw-100 me-2" 
          @click="resetForm()"
        >
          Cancelar
        </button>
        <button
          class="btn btn-custom-color-blue border-round-50 mw-100"
          @click="addOrEdit()"
          :disabled="!isValidQuestion"
        >
        {{ boton_enviar }}
        <i class="fa-solid fa-arrow-right ps-2"></i>
        </button>
      </div>
    </div>

  </section>

</template>

<script>
import { mapActions, mapState } from "vuex";

export default {
  props: {
    index: { type: Number, },
    editingPregunta: { type: Object },
  },
  data() {
    return {
      texto_pregunta: "",
      boton_enviar: "Añadir",
      id_pregunta: "0",
    };
  },

  computed: {
    ...mapState("encuestasModule", ["encuesta"]),

    isValidQuestion() {
      /* TODO: añadir validación -> alternativas pobladas */
      return this.texto_pregunta.trim() !== "";
    },
  },

  methods: {
    ...mapActions("encuestasModule", ["addPreguntaToEncuesta", "updateQuestionInEncuesta"]),

    setEditingQuestion() {
      this.id_pregunta = this.editingPregunta.id_pregunta;
      this.texto_pregunta = this.editingPregunta.pregunta;
      this.boton_enviar = "Guardar";
    },

    addOrEdit() {
      const action = this.boton_enviar === "Añadir" ? this.addQuestion : this.editQuestion;
      action();
    },

    addQuestion() {
      this.addPreguntaToEncuesta(this.createQuestionObject());
      this.resetForm();
    },
    editQuestion() {
      this.updateQuestionInEncuesta({ index: this.index, pregunta: this.createQuestionObject() });
      this.resetForm();
    },
    resetForm() {
      this.texto_pregunta = "";
      this.id_pregunta = "0";
      this.boton_enviar = "Añadir";
      this.$emit("add-or-edit-pregunta");
      this.$Progress.finish();
    },

    createQuestionObject() {
      return {
        id_pregunta: this.id_pregunta,
        tipo_pregunta: "1",
        pregunta: this.texto_pregunta,
        editable: "1",
        calificacion_puntos: "0",
        calificacion_estrellas: "0",
        opciones: [],
      };
    },

  },
};
</script>