<template>
  <div class="card flex-grow-1">
    <div class="card-body">
      <h6 class="font-main-bold color-main text-center">Tipo de Pregunta</h6>
      <div class="list-group">
        <a 
          v-for="pregunta in preguntas"
          :key="pregunta.id"
          href="javascript:" 
          @click="selectComponent(pregunta.component, pregunta)"
          class="list-group-item list-group-item-action border border-2 rounded-pill mb-2"
          :class="{ 
            'link-primary border-primary': pregunta.component === currentComponent, 
            'text-secondary': pregunta.component !== currentComponent 
          }"
        >
          <i :class="pregunta.icon"></i>
          {{ pregunta.label }}
        </a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    preguntas: Array,
    currentComponent: String
  },
  methods: {
    selectComponent(component, pregunta) {
      this.$emit('changeComponent', component, pregunta);
    }
  }
}
</script>
