<template>

  <section id="form-encuesta-calificacion">
    <div class="row">
      <div class="col-12">
        <label class="form-label text-secondary">Ingresa la pregunta</label>
          <input 
            type="text" 
            class="form-control input-custom"
            maxlength="170"
            v-model="texto_pregunta"
          >
      </div>
    </div>

    <div class="row">
      <div class="col-12">
        <div class="border-bottom mb-3 pb-3"></div>
      </div>
    </div>

    <div class="row">
      <div class="col-12">
        <label class="form-label text-secondary">Respuesta</label>
        <ul class="list-inline">
          <li class="list-inline-item">
            <a 
              href="javascript:" 
              class="btn border btn-custom-color-white"
              @click="rango_estrellas = 1"
              >
              <font-awesome-icon 
              v-if="rango_estrellas < 1"
              :icon="['fa-regular', 'star']"
              />
              <font-awesome-icon 
              v-if="rango_estrellas > 0"
              :icon="['fa-solid', 'star']"
              />
            </a>
          </li>
          <li class="list-inline-item">
            <a 
              href="javascript:" 
              class="btn border btn-custom-color-white"
              @click="rango_estrellas = 2"
              >
              <font-awesome-icon 
              v-if="rango_estrellas < 2"
              :icon="['fa-regular', 'star']"
              />
              <font-awesome-icon 
              v-if="rango_estrellas > 1"
              :icon="['fa-solid', 'star']"
              />
            </a>
          </li>
          <li class="list-inline-item">
            <a 
              href="javascript:" 
              class="btn border btn-custom-color-white"
              @click="rango_estrellas = 3"
              >
              <font-awesome-icon 
              v-if="rango_estrellas < 3"
              :icon="['fa-regular', 'star']"
              />
              <font-awesome-icon 
              v-if="rango_estrellas > 2"
              :icon="['fa-solid', 'star']"
              />
            </a>
          </li>
          <li class="list-inline-item">
            <a 
              href="javascript:" 
              class="btn border btn-custom-color-white"
              @click="rango_estrellas = 4"
              >
              <font-awesome-icon 
              v-if="rango_estrellas < 4"
              :icon="['fa-regular', 'star']"
              />
              <font-awesome-icon 
              v-if="rango_estrellas > 3"
              :icon="['fa-solid', 'star']"
              />
            </a>
          </li>
          <li class="list-inline-item">
            <a 
              href="javascript:" 
              class="btn border btn-custom-color-white"
              @click="rango_estrellas = 5"
              >
              <font-awesome-icon 
              v-if="rango_estrellas < 5"
              :icon="['fa-regular', 'star']"
              />
              <font-awesome-icon 
              v-if="rango_estrellas > 4"
              :icon="['fa-solid', 'star']"
              />
            </a>
          </li>
        </ul>
      </div>
    </div>

    <div class="row flex-grow-1">
      <div class="col-12 d-flex align-items-end justify-content-end">
        <button 
          v-if="boton_enviar == 'Guardar'"
          class="btn btn-custom-color-white border border-round-50 mw-100 me-2" 
          @click="resetForm()"
          >
          Cancelar
        </button>
        <button
          class="btn btn-custom-color-blue border-round-50 mw-100"
          @click="addOrEdit()"
          :disabled="!isValidQuestion"
        >
        {{ boton_enviar }}
        <i class="fa-solid fa-arrow-right ps-2"></i>
        </button>
      </div>
    </div>
  </section>

</template>

<script>
import { mapActions, mapState } from "vuex";

export default {
  props: {
    index: { type: Number },
    editingPregunta: { type: Object },
  },
  data() {
    return {
      texto_pregunta: "",
      boton_enviar: "Añadir",
      id_pregunta: "0",
      opciones_edit: [],

      //FF 
      rango_estrellas: 0
    };
  },
  
  computed: {
    ...mapState("encuestasModule", ["encuesta"]),

    isValidQuestion() {
      /* TODO: añadir validación -> alternativas pobladas */
      return this.texto_pregunta.trim() !== "";
    },

  },
  
  methods: {
    ...mapActions("encuestasModule", ["addPreguntaToEncuesta", "updateQuestionInEncuesta"]),

    setEditingQuestion() {
      this.id_pregunta = this.editingPregunta.id_pregunta;
      this.texto_pregunta = this.editingPregunta.pregunta;
      this.opciones_edit = this.editingPregunta.opciones;
      this.boton_enviar = "Guardar";
    },

    // Controlador de añadir o editar
    addOrEdit() {
      const action = this.boton_enviar === "Añadir" ? this.addQuestion : this.editQuestion;
      action();
    },
    
    addQuestion() {
      this.addPreguntaToEncuesta(this.createQuestionObject(this.generateOptions));
      this.resetForm();
    },

    editQuestion() {
      this.updateQuestionInEncuesta({ index: this.index, pregunta: this.createQuestionObject(this.generateOptionsForEdit) });
      this.resetForm();
    },

    resetForm() {
      this.texto_pregunta = "";
      this.id_pregunta = "0";
      this.boton_enviar = "Añadir";
      this.opciones_edit = [];
      this.$emit("add-or-edit-pregunta");
      this.$Progress.finish();
    },

    createQuestionObject(optionsGeneratorFunction) {
      return {
        id_pregunta: this.id_pregunta,
        tipo_pregunta: "4",
        pregunta: this.texto_pregunta,
        editable: "1",
        calificacion_puntos: "0",
        calificacion_estrellas: "1",
        opciones: optionsGeneratorFunction(),
      };
    },

    generateOptions() {
      return Array.from({ length: 5 }, (_, i) => ({
        id_alternativa: "0",
        genera_qr: "0",
        correcta_alternativa: "1",
        opcion: `${i + 1}`,
      }));
    },

    generateOptionsForEdit() {
    const baseOptions = this.generateOptions();
    const updatedOptions = this.opciones_edit.map((editOption, index) => {
      if (index < baseOptions.length) {
        return { ...baseOptions[index], id_alternativa: editOption.id_alternativa };
      }
      return {
        id_alternativa: editOption.id_alternativa,
        genera_qr: "0",
        correcta_alternativa: "1",
        opcion: "delete",
      };
    });

    return updatedOptions;
  }
  },
};
</script>
<style scoped>
.star-active,
.star-disable {
  color: #0072f7;
}
</style>
