<template>
  <section id="form-encuesta-step-2-question-type-1">
    <div class="row">
      <div class="col-12">
        <div class="row">
          <div class="col-12 col-lg-9">
            <label class="form-label text-secondary">
              Ingresa la pregunta
            </label>
            <input 
              class="form-control input-custom"
              maxlength="170" 
              type="text" 
              v-model="texto_pregunta"
            />
          </div>
          <div class="col-12 col-lg-3 v-select-container">
            <label class="form-label text-secondary">
              Tipo de selección
              <span 
                class="link-cursor"
                content="Esta selección le permitirá al usuario elegir una o múltiples respuestas."
                v-tippy="{ arrow: true }">
                <i class="fa-regular fa-circle-question"></i>
              </span>
            </label>
            <v-select 
              class="selvue-custom"
              v-model="tipo_pregunta" 
              label="name" 
              :reduce="name => name.id"
              :options="opciones_tipo_seleccion" 
              :clearable="false"
            >
            </v-select>
          </div>
        </div>
        <div class="border-bottom border-1 mb-3 pb-3"></div>
      </div>
    </div>

    <div class="row">
      <div class="col-12">
        <div class="alert alert-light border d-flex align-items-center justify-content-between">
          <label for="hasCorrectAnswer" class="form-check-label link-cursor text-secondary">
            ¿Deseas seleccionar una respuesta correcta?
          </label>
          <div class="form-check form-switch">
            <input 
              id="hasCorrectAnswer"
              class="form-check-input link-cursor" 
              type="checkbox" 
              role="switch"
              v-model="hasCorrectAnswer"
            >
          </div>
        </div>
      </div>
    </div>

    <div class="row mb-4">
      <div class="col-12 d-flex align-items-end justify-content-start">
        <label class="form-check form-check-label text-secondary me-4">
          Tipo de alternativa:
        </label>
        <div class="form-check form-check-inline">
          <input class="form-check-input" type="radio" value="Texto" v-model="tipoAlternativa">
          <label class="form-check-label" for="inlineRadio1">Texto</label>
        </div>
        <div class="form-check form-check-inline">
          <input class="form-check-input" type="radio" value="Imagen" v-model="tipoAlternativa">
          <label class="form-check-label" for="inlineRadio2">Imagen</label>
        </div>
        <div class="form-check form-check-inline">
          <input class="form-check-input" type="radio" value="Video" v-model="tipoAlternativa">
          <label class="form-check-label" for="inlineRadio3">Video</label>
        </div>
      </div>
    </div>


    <div class="row">
      <div 
        v-for="(alt, idx) in activeAlternatives" 
        :key="idx"
        class="col-6 position-relative mb-2"
      >
        <AlternativaElement
          :alternative="alt"
          :show-correct-option="hasCorrectAnswer"
          :show-delete="activeAlternatives.length > 2 && idx > 1"
          :alternativeIndex="idx"
          @delete="deleteAlternative"
          :mediaType="tipoAlternativa"
          @update-correct="updateCorrectAlternative"
          />
      </div>
      <div class="col-6 mb-3">
        <a 
          @click="addAlternatives()"
          class="btn btn-custom btn-custom-color-white border justify-content-between"
        >
          Agregar alternativa
          <i class="fa-solid fa-circle-plus color-secondary"></i>
        </a>
      </div>
    </div>

    <div class="row flex-grow-1">
      <div class="col-12 d-flex align-items-end justify-content-end">
        <button 
          v-if="boton_enviar == 'Guardar'"
          class="btn btn-custom-color-white border border-round-50 mw-100 me-2" 
          @click="resetForm()"
        >
          Cancelar
        </button>
        <button
          class="btn btn-custom-color-blue border-round-50 mw-100"
          @click="addOrEdit()"
          :disabled="!isValidQuestion"
        >
          {{ boton_enviar }} <i class="fa-solid fa-arrow-right ps-2"></i>
        </button>
      </div>
    </div>


  </section>

</template>

<script>
import AlternativaElement from './AlternativaElement.vue';
import { mapActions, mapState } from "vuex";

export default {
  components: {
    AlternativaElement
  },
  props: {
    index: { type: Number },
    editingPregunta: { type: Object },
  },
  data() {
    return {
      max_chars: 150,
      texto_pregunta: "",
      tipo_pregunta: "2",
      hasCorrectAnswer: false,
      boton_enviar: "Añadir",
      alternativesTxt: [
        { data: "", correcta_alternativa: "0", metadata: {} },
        { data: "", correcta_alternativa: "0", metadata: {} },
      ],
      alternativesImg: [
        { data: "", correcta_alternativa: "0", metadata: {} },
        { data: "", correcta_alternativa: "0", metadata: {} },
      ],
      alternativesVid: [
        { data: "", correcta_alternativa: "0", metadata: {} },
        { data: "", correcta_alternativa: "0", metadata: {} },
      ],
      // modal status
      id_pregunta: "0",
      opciones_tipo_seleccion: [
        { id: "2", name: 'Única' },
        { id: "3", name: 'Múltiple' }
      ],
      tipoAlternativa: "Texto",
      opciones_edit: []
    };
  },
  watch: {
    tipoAlternativa: function (){
      this.resetAlternatives();
    }
  },
  computed: {
    ...mapState("encuestasModule", ["encuesta"]),

    isValidQuestion() {
      /* TODO: añadir validación -> alternativas pobladas */
      return this.texto_pregunta.trim() !== "";
    },

    activeAlternatives() {
      switch (this.tipoAlternativa) {
        case 'Texto': return this.alternativesTxt;
        case 'Imagen': return this.alternativesImg;
        case 'Video': return this.alternativesVid;
        default:
          return [];
      }
    }
  },

  methods: {
    ...mapActions("encuestasModule", ["addPreguntaToEncuesta", "updateQuestionInEncuesta"]),

    setEditingQuestion() {
      this.id_pregunta = this.editingPregunta.id_pregunta;
      this.texto_pregunta = this.editingPregunta.pregunta;
      this.tipo_pregunta = this.getTipoPreguntaFromEdit();
      this.hasCorrectAnswer = this.isThereCorrectAnswer(this.editingPregunta.opciones);
      this.setQuestionContents(this.editingPregunta.opciones);
      this.opciones_edit = this.editingPregunta.opciones;
      this.boton_enviar = "Guardar";
    },

    getTipoPreguntaFromEdit() {
      if (this.editingPregunta.tipo_pregunta === "2" || this.editingPregunta.tipo_pregunta === "6") {
        return "2";
      } else if (this.editingPregunta.tipo_pregunta === "3" || this.editingPregunta.tipo_pregunta === "7") {
        return "3";
      } else {
        return null;
      }
    },

    isThereCorrectAnswer(options) {
      return options.some(option => option.correcta_alternativa === "1");
    },

    setQuestionContents(opciones) {
      // Clear current alternatives
      this.alternativesTxt = [];
      this.alternativesImg = [];
      this.alternativesVid = [];

      opciones.forEach(opcion => {
        // Check if the option is a video
        if (opcion.opcion.match(/\.(mp4|avi|mov)$/i)) {
          this.tipoAlternativa = 'Video';
          this.alternativesVid.push({
            data: opcion.opcion,
            correcta_alternativa: opcion.correcta_alternativa,
            metadata: opcion.metadata || {}
          });
        }
        // Check if the option is an image
        else if (opcion.opcion.match(/\.(jpg|jpeg|png|gif)$/i)) {
          this.tipoAlternativa = 'Imagen';
          this.alternativesImg.push({
            data: opcion.opcion,
            correcta_alternativa: opcion.correcta_alternativa,
            metadata: opcion.metadata || {}
          });
        }
        // Otherwise, assume it is text
        else {
          this.tipoAlternativa = 'Texto';
          this.alternativesTxt.push({
            data: opcion.opcion,
            correcta_alternativa: opcion.correcta_alternativa,
            metadata: opcion.metadata || {}
          });
        }
      });
    },

    addOrEdit() {
      const action = this.boton_enviar === "Añadir" ? this.addQuestion : this.editQuestion;
      action();
    },

    addQuestion() {
      this.addPreguntaToEncuesta(this.createQuestionObject(this.generateOptions));
      this.resetForm();
    },
    editQuestion() {
      this.updateQuestionInEncuesta({ index: this.index, pregunta: this.createQuestionObject(this.generateOptionsForEdit) });
      this.resetForm();
    },
    resetAlternatives() {
      this.alternativesTxt = [
        { data: "", correcta_alternativa: "0", metadata: {} },
        { data: "", correcta_alternativa: "0", metadata: {} },
      ];
      this.alternativesImg = [
        { data: "", correcta_alternativa: "0", metadata: {} },
        { data: "", correcta_alternativa: "0", metadata: {} },
      ];
      this.alternativesVid = [
        { data: "", correcta_alternativa: "0", metadata: {} },
        { data: "", correcta_alternativa: "0", metadata: {} },
      ];
    },
    resetForm() {
      this.texto_pregunta = "";
      this.id_pregunta = "0";
      this.tipo_pregunta = "2";
      this.hasCorrectAnswer = false;
      this.tipoAlternativa = "Texto";
      this.opciones_edit = [];
      this.resetAlternatives();
      this.boton_enviar = "Añadir";
      this.$emit("add-or-edit-pregunta");
      this.$Progress.finish();
    },

    createQuestionObject(optionsGeneratorFunction) {
      return {
        id_pregunta: this.id_pregunta,
        tipo_pregunta: this.tipoAlternativa == "Texto" ?  this.tipo_pregunta : `${+this.tipo_pregunta+4}`,
        pregunta: this.texto_pregunta,
        editable: "1",
        calificacion_puntos: "0",
        calificacion_estrellas: "0",
        opcion_alternativa: this.tipoAlternativa,
        opciones: optionsGeneratorFunction(),
      };
    },

    generateOptions() {
      return this.activeAlternatives.map(alt => ({
        id_alternativa: "0",
        genera_qr: "0",
        correcta_alternativa: this.hasCorrectAnswer ? alt.correcta_alternativa : "0",
        opcion: alt.data
      }))
    },

    
    generateOptionsForEdit() {
      let antiguo = this.opciones_edit;
      let nuevo = this.generateOptions();
      // cambiando solo ids alternativas antiguas
      let optionEdit = [];
      // son iguales
      if (antiguo.length == nuevo.length) {
        for (let i = 0; i < antiguo.length; i++) {
          optionEdit.push({
            id_alternativa: antiguo[i].id_alternativa,
            genera_qr: "0",
            correcta_alternativa: nuevo[i].correcta_alternativa,
            opcion: nuevo[i].opcion,
          });
        }
      }
      // antiguo < nuevo
      if (antiguo.length < nuevo.length) {
        for (let i = 0; i < antiguo.length; i++) {
          nuevo[i].id_alternativa = antiguo[i].id_alternativa;
        }
        optionEdit = nuevo;
      }
      // antiguo > nuevo
      if (antiguo.length > nuevo.length) {
        for (let i = 0; i < nuevo.length; i++) {
          nuevo[i].id_alternativa = antiguo[i].id_alternativa;
        }
        optionEdit = nuevo;
        for (let i = nuevo.length; i < antiguo.length; i++) {
          optionEdit.push({
            id_alternativa: antiguo[i].id_alternativa,
            genera_qr: "0",
            correcta_alternativa: "",
            opcion: "delete",
          });
        }
      }
      return optionEdit;
    },


    updateCorrectAlternative(index) {
      this.activeAlternatives.forEach((alternative, i) => {
        alternative.correcta_alternativa = i === index ? '1' : '0';
      });
    },
    
    addAlternatives() {
      const alternativa = {
        id: this.activeAlternatives.length + 1,
        data: "",
        correcta_alternativa: "",
      };
      this.activeAlternatives.push(alternativa);
    },

    // Eliminar alternativa
    deleteAlternative(i){
      this.activeAlternatives.splice(i, 1);
    }
  },
};
</script>
