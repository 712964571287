<template>
<div class="card flex-grow-1">
  <div class="card-body">
    <h6 class="font-main-bold color-main text-center">Historial preguntas</h6>
    <ul class="list-group">
      <li 
        v-for="(question, index) in preguntas" 
        :key="index"
        class="list-group-item d-flex justify-content-between align-items-center border text-secondary rounded-pill mb-2 link-custom"
      >
        <span class="d-flex">
          <span class="badge color-main border border-primary rounded-pill me-1">
            {{ index + 1 }}
          </span>
          {{ nameQuestion(question) }}
        </span>
        <span class="link-custom-hover">
          <a href="javascript:" @click="edit(question, index)" class="me-2">
            <i class="fa-solid fa-pencil color-brown"></i>
          </a>
          <a href="javascript:" @click="remove(index)">
            <i class="fa-solid fa-trash-can color-red"></i>
          </a>
        </span>
      </li>
    </ul>
  </div>
</div>
</template>

<script>
export default {
  props: {
    preguntas: Array
  },
  methods: {
    edit(question, index) {
      this.$emit('edit-question', index);
    },
    remove(index) {
      this.$emit('delete-question', index);
    },
    nameQuestion(question){
      if (question.tipo_pregunta == "1") return 'Desarrollo';
      if (["2","3","6","7"].includes(question.tipo_pregunta)) return 'Alternativas';
      if (question.tipo_pregunta == "4" && question.calificacion_estrellas == "1") return 'Calificación';
      if (question.tipo_pregunta == "4" && question.calificacion_estrellas == "0") return 'Escala lineal';
      if (question.tipo_pregunta == "5") return 'Satisfacción';
    },
  }
}
</script>
